* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  --lightgray: #c9c9c9;
  --gray: #999999;
  --darkgray: #363636;
}

.App-header {
  background-color: var(--color-grey);
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 15px;
}

.header-logo {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;

  img {
    height: 35px;
    margin-right: 5px;
  }
}

.App-link {
  color: var(--color-pink);
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    filter: brightness(0.8);
  }
}
