.logo-wrapper, .hero-wrapper {
  max-width: 500px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.hero-wrapper {
  max-width: 550px;
}