.scrambler {
  h1 {
    margin: 0;
    color: var(--color-purple-light);
  }

  display: flex;
  flex-direction: column;

  .board-wrap {
    display: flex;
    flex-direction: column;
  }

  .board {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .answer-column {
    display: flex;
    flex-direction: column;
  }

  .answer-row,
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
    justify-content: flex-start;
  }

  .letter-rows {
    display: flex;
    flex-direction: column;
    margin: 15px auto;
    width: 100%;
    max-width: 550px;
    justify-content: center;
    .row {
      justify-content: flex-start;
    }
  }

  .blank {
    border-bottom: 1px solid gray;
    width: 35px;
    height: 40px;
    font-size: 35px;
    margin: 0 5px;
    text-transform: uppercase;
    padding: 5px;
    line-height: 1;
  }

  .letter {
    font-size: 50px;
    text-transform: uppercase;
    min-width: 65px;
    padding: 5px 10px;
    background: beige;
    margin: 0 5px;
    border-radius: 4px;
    border: 1px solid gray;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    z-index: 2;
  }

  .letter:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .tray {
    background: var(--color-purple-light);
    height: 100px;
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    flex: 0 0 500px;
    position: relative;
    flex-direction: row;
  }

  .answerInput {
    width: 480px;
    border: 0;
    background: transparent;
    height: 100px;
    font-size: 22px;
    text-transform: uppercase;
    position: absolute;
    color: transparent;
    z-index: 1;
    &:focus {
      border: none;
    }
  }

  button {
    padding: 10px 15px;
    background: var(--color-grey);
    color: var(--color-pink);
    border: 0;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .action-btn {
    width: auto;
    font-weight: bold;
    text-align: center;
    padding: 10px 10px;
    align-self: center;
    margin-left: 10px;
    border-radius: 3px;
    cursor: pointer;
  }

  .error {
    font-size: 18px;
    color: darkred;
    font-weight: bold;
    text-align: center;
  }

  .text {
    font-size: 18px;
    text-align: left;
    margin: 15px;
  }
}
