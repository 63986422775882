h1 {
  color: var(--color-purple);
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 6px rgba(0, 0, 0, 0.24);
  }
  70% {
    box-shadow: 0 16px 35px rgba(0, 0, 0, 0.25), 0 14px 14px rgba(0, 0, 0, 0.22);
  }
  100% {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 6px rgba(0, 0, 0, 0.24);
  }
}

.tile {
  width: 100px;
  height: 100px;
  background: var(--color-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &.selected {
    animation: pulse 1s infinite;
  }

  svg {
    width: 90%;
    height: 90%;
  }
}

.board-tile {
  width: 100%;
  height: 100%;
}

.pink {
  fill: var(--color-pink);
}

.orange {
  fill: #EE7B30;
}

.blue {
  fill: #74B3CE;
}

.purple {
  fill: #885A89;
}

.yellow {
  fill: #E0FF4F;
}

.green {
  fill: #50A477;
}

.tray {
  width: 115px;
  height: 635px;
  background: gray;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  border: 2px solid gray;
  border-radius: 8px;
  box-sizing: border-box;
}

.grid-wrapper {
  display: block;
  position: relative;
  width: 900px;
  height: 550px;
  overflow: hidden;
  border: 2px solid var(--color-grey);

  .grid-zoom {
    position: absolute;
    top: -50px;
    left: -50px;
    cursor: grab;
  }

  .row {
    display: flex;
  }

  .cell {
    width: 50px;
    height: 50px;
    background: white;
    border: 1px solid #cecece;
  }
}