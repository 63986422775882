
:root {
  --color-pink: #FF4E80;
  --color-purple-light: #885A89;
  --color-purple: #38347D;
  --color-purple-dark: #06005C;
  --color-grey: #333;
  --color-grey-light: #c9c9c9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
