.wordle {
  h1 {
    color: var(--color-light);
  }

  .board-wrap {
    display: flex;
    justify-content: center;
  }

  .board {
    min-width: 100px;
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  input {
    display: block;
    width: 100%;
    font-size: 22px;
    height: 55px;
    padding: 5px 15px;
    margin: 15px 0;
    text-transform: uppercase;
    border: 1px solid var(--lightgray);
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .cell {
    width: 60px;
    height: 60px;
    margin: 5px;
    text-transform: uppercase;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }

  .board .cell {
    border: 1px solid var(--gray);
  }

  .yellow {
    background-color: gold;
  }

  .green {
    background-color: green;
  }

  .gray {
    background-color: #cfcfcf;
  }

  .darkgray {
    background-color: #636363;
    color: #cfcfcf;
  }

  .keyboard {
    margin-top: 15px;
  }

  .keyboard .cell {
    width: 35px;
    height: 40px;
    cursor: pointer;
    margin: 2px;
    padding: 5px;
    border: 1px solid transparent;
  }

  .keyboard .cell:hover {
    box-shadow: 1px 1px 1px rgba(0,0,0,0.24);
  }

  .keyboard .cell.long-btn {
    width: auto;
    padding: 0 5px;
    background-color: var(--lightgray);
  }

  .keyboard .cell:active {
    box-shadow: none;
    border-color: var(--dark-gray);
  }

  .keyboard .row {
    justify-content: center;
  }

  .error {
    font-size: 18px;
    color: darkred;
    font-weight: bold;
  }

  button {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--gray);
    background: var(--color-grey);
    color: var(--color-pink);
    font-size: 22px;
    margin: 5px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      box-shadow: 0 1px 2px rgba(0,0,0,0.24);
    }

    &:active {
      border-color: var(--darkgray);
      box-shadow: none;
    }
  }
}