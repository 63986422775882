.wrapper {
  display: flex;
  width: 100%;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
}

.game-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .number-verticals {
    display: flex;

    .v-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      border-top: 2px solid transparent;

      &.correct {
        border-top-color: green;
        background: rgba(0, 255, 0, 0.2);
      }

      &.wrong {
        border-top-color: darkred;
        background: rgba(255, 0, 0, 0.2);
      }
    }

    .v-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 30px;
    }
  }
}

.secondary-wrap {
  display: flex;
  align-items: flex-start;

  .number-horizontals {
    min-width: 50px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .h-row {
      display: flex;
      justify-content: flex-end;
      border-left: 2px solid transparent;

      &.correct {
        border-left-color: green;
        background: rgba(0, 255, 0, 0.2);
      }

      &.wrong {
        border-left-color: darkred;
        background: rgba(255, 0, 0, 0.2);
      }
    }

    .h-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 50px;
    }
  }
}

.grid {
  background: white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.grid-row {
  display: block;
  white-space: nowrap;
  height: 50px;
}

.grid-cell {
  border: 1px solid black;
  width: 50px;
  height: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: #c8c8c8;

  &.exed {
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "\2715";
      font-size: 30px; 
      line-height: 50px;
      text-align: center;
    }
  }

  &.colored {
    background-color: black;
    border-color: white;
  }

  &.colored.wrong {
    background-color: darkred;
  }

  &.exed.wrong {
    background-color: rgba(255, 0, 0, 0.2);
    color: darkred;
  }
}

.controls {
  text-align: left;
  margin: 15px;
}

button {
  border: 1px solid #c3c3c3;
  background: white;
  color: grey;
  font-size: 18px;
  padding: 10px 25px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    border-color: black;
  }
}

.winner-banner {
  border: 2px solid green;
  border-radius: 4px;
  background: rgba(0, 255, 0, 0.2);
  color: green;
  font-size: 18px;
  text-align: center;
  padding: 10px 25px;
  margin: 5px 25px;
  font-weight: bold;
}
